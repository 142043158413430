<template lang="html">
	<article class="ps-post ps-post--grid" v-if="post.provider == 'epoch'">
		<div class="ps-post__thumbnail">
			<router-link :to="'/read/' + articleId" class="ps-post__overlay" />
			<module-post-thumbnail-image :post="post" />
		</div>
		<div class="ps-post__content">
			<div class="ps-post__categories">
				<template v-for="item in post.category">
					<router-link :to="categoryPath(item)" v-if="categoryPath(item)" :key="item">
						{{ item }}
					</router-link>
				</template>
			</div>
			<h4 class="ps-post__title">
				<router-link :to="'/read/' + articleId">
					{{ post.title[0] }}
				</router-link>
			</h4>
			<div class="ps-post__meta">
				{{ post['dc:creator'][0] }}
				<span class="ps-post__posted">{{ post.pubDate[0] | moment('MMM Do, YYYY') }}</span>
			</div>
			<div class="ps-post__short-desc">
				<p v-html="post.description[0]"></p>
			</div>
			<div class="ps-post__footer">
				<router-link :to="'/read/' + articleId" class="ps-post__morelink effect--underline">
					Read more
				</router-link>
			</div>
		</div>
	</article>
	<article class="ps-post ps-post--grid" v-else>
		<div class="ps-post__thumbnail">
			<a :href="post.link[0]" class="ps-post__overlay" target="_blank" />
			<module-post-thumbnail-image :post="post" />
		</div>
		<div class="ps-post__content">
			<div class="ps-post__categories">
				<template v-for="item in post.category">
					<router-link :to="categoryPath(item)" v-if="categoryPath(item)" :key="item">
						{{ item }}
					</router-link>
				</template>
			</div>
			<h4 class="ps-post__title">
				<a :href="post.link[0]" target="_blank">
					{{ post.title[0] }}
				</a>
			</h4>
			<div class="ps-post__meta">
				{{ post['source'][0]._ }}
				<span class="ps-post__posted">{{ post.pubDate[0] | moment('MMM Do, YYYY') }}</span>
			</div>
			<div class="ps-post__short-desc">
			</div>
			<div class="ps-post__footer">
				<a :href="post.link[0]" class="ps-post__morelink effect--underline" target="_blank">
					Read more
				</a>
			</div>
		</div>
	</article>
</template>

<script>
import ModulePostThumbnailImage from '@/components/elements/posts/modules/ModulePostThumbnailImage';

export default {
	name: 'ArticleGrid',
	components: { ModulePostThumbnailImage },
	props: ['post'],
	data () {
		return {
			categoryMap: {
				'US News': 'c-us-news',
				'US Features': 'c-us-features',
				'Politics': 'c-us-politics',
				'Crime and Incidents': 'c-crime-us',
				'New York': 'c-nyc',
				'San Francisco': 'c-nor-cal-san-francisco',
				'Los Angeles': 'c-so-cal-los-angeles',
				'Opinion': 'c-opinion',
				'Thinking About China': 'c-thinking-about-china',
				'Viewpoints': 'c-viewpoints',
			}
		}
	},
	computed: {
		articleId () {
			return this.post.link[0].split('_')[1].split('.')[0];
		}
	},
	methods: {
		categoryPath (item) {
			return typeof this.categoryMap[item] === "undefined" ? false : '/category/' + this.categoryMap[item];
		}
	}
};
</script>

<style lang="scss" scoped>
	.ps-post__categories {
		a {
			background: pink;
			padding: 3px;
			margin: 0px 2px;
			border-radius: 5px;
			
			&:hover {
				background: #ef534f;
				color: #fff;
			}
		}
	}
</style>
