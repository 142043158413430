<template lang="html">
    <main id="homepage-grid">
        <div class="container">
            <!--<subscribe />-->
            <module-blog-post-grids :column="3" />
        </div>
    </main>
</template>
<script>
import { mapState } from 'vuex';
//import Subscribe from '@/components/shared/sections/Subscribe';
import ModuleBlogPostGrids from '@/components/partials/blog/modules/ModuleBlogPostGrids';
export default {
    components: {
        ModuleBlogPostGrids,
//        Subscribe
    },
    transition: 'zoom',
    layout: 'layout-home-default',
    head: {
        title: 'Home Grid'
    },

    computed: {
        ...mapState({
            collections: state => state.collection.collections
        })
    },
    created() {
        this.$store.commit('app/toggleDrawer', false);
    }
};
</script>

<style lang="scss" scoped>
	.container {
		padding-top: 150px;
	}
</style>
