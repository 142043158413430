<template lang="html">
    <img :src="media.url" :alt="post.title" />
</template>

<script>
export default {
    name: 'ModulePostThumbnailImage',
    computed: {
        media () {
            return this.post['media:content'][this.post['media:content'].length - 1].$;
        }
    },
    props: {
        post: {
            default: {}
        },
        wide: {
            type: Boolean,
            default: () => false
        }
    }
};
</script>

<style lang="scss" scoped></style>
